<script lang="ts" setup>

  // Props
  defineProps({
    titulo: String,
    subtitulo: String
  })

  // Scroll Store
  const scroll: any = useScrollStore()
  const { isScrolledInput } = storeToRefs(scroll)
</script>

<template>
  <div class="relative h-[calc(100vh-50px)] flex items-center w-full">
    <!-- Imagen -->
    <div class="absolute inset-0 dark:border-b border-gray-700 bg-[#605c54] dark:bg-transparent">
      <NuxtImg
        provider="s3Provider"
        preload
        format="webp"
        src="/home/background.webp"
        alt="Fondo cama hotel"
        class="brightness-50 object-cover w-full h-full dark:opacity-30 dark:brightness-75 dark:grayscale"
      />
    </div>  
    <div class="mx-auto w-full max-w-screen-xl px-5">
      <!-- Titulo -->
      <div class="relative py-8 md:-mt-[15vh]">
        <h1 class="text-white text-7xl md:text-9xl pb-4">{{ titulo }}</h1>
        <p class="text-gray-100 text-lg md:text-2xl dark:text-gray-300 md:mr-[24vw]">{{ subtitulo }}</p>
      </div>

      <!-- Inputs -->
      <div v-show="!isScrolledInput">
        <HomeSearchInput/>
      </div>
    </div>
  </div>
</template>